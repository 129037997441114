main {
 @if $type == pc{
  font-size: 15px;
 }
}

@if $type == ""{
}
@if $type == sp{
}
@if $type == pc{

}

@keyframes scroll {
 0% { height: 98px;}
 70% { height: 0;}
 100% { height: 0;}
}

@keyframes slickActiveSP {
 0% { width: 0}
 100% { width: 100%}
}
@keyframes slickActivePC {
 0% { width: 0}
 100% { width: calc(100% - 40.1vw);}
}

main {
 .sectionHeader {
  @if $type == ""{
  }
  @if $type == sp{
  }
  @if $type == pc{
  }

  .sectionTitle {
   @if $type == ""{
    text-align: center;
    font-weight: 500;
   }
   @if $type == sp{
    margin-bottom: 8px;
    font-size: 24px;
   }
   @if $type == pc{
    margin-bottom: 2px;
    font-size: 30px;
   }
  }

  .sectionTitleSub {
   @if $type == ""{
    text-align: center;
    color: $c_base;
    line-height: 1;
    font-weight: bold;
    @include fontRo;
   }
   @if $type == sp{
    font-size: 12px;
   }
   @if $type == pc{
    font-size: 14px;
   }
  }
 }

 .inner {
  @if $type == sp{
   @include maxSP;
  }

  @if $type == pc{
   @include maxPC;
  }
 }
}


#topHero {
 @if $type == ""{
  position: relative;
 }
 @if $type == sp{
  box-shadow: 0 3px 4px rgba( 0, 0,  0, .05);
  z-index: 1;
 }
 @if $type == pc{
  padding-left: 6%;
 }

 .heroScroll {
  @if $type == sp{
   display: none !important;
  }
  @if $type == pc{
   @include fontRo;
   -ms-writing-mode: tb-rl;
   writing-mode: vertical-rl;
   position: absolute;
   font-weight: bold;
   position: absolute;
   bottom: 0;
   left: 2.28%;
   z-index: 10;

   &:before {
    content: "";
    width: 1px;
    height: 98px;
    background: $c_base;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    animation: 1.5s scroll infinite ease-out;
   }
  }

  a {
   @if $type == pc{
    display: block;
    padding-bottom: 110px;
    color: $c_base;
    font-size: 0.69vw;
    letter-spacing: 0.06em;
   }
  }
 }
 
 .heroContent {
  @if $type == ""{
   overflow: hidden;
  }
  @if $type == sp{
   height: 128vw;
  }
  @if $type == pc{
   height: calc(37.28vw + 30px);
  }
 }

 .heroSlide {
  @if $type == ""{
  }
  @if $type == sp{
   margin-bottom: 0;
  }
  @if $type == pc{
   position: relative;
   z-index: 11;
  }

  .heroSlideItem {
   @if $type == ""{
    > a {
     display: block;
     width: 100%;
     height: 100%;
    }
   }
   @if $type == sp{
    display: flex;
    align-items: flex-end;
    height: 128vw;
    
    > a {
     display: flex;
     align-items: flex-end;
    }
   }
   @if $type == pc{
    height: 37.28vw;
    
    > a {
     
     .heroSlideBtn:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255, .1);
      position: absolute;
      top: 0;
      left: -100%;
      transition: .2s left;
     }
    }
    
    > a:hover {
     .heroSlideBtn:after {
      left: 0;
     }
    }
   }
   
   .heroSlideImg {
    @if $type == ""{
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     left: 0;
     z-index: 0;
     
     img {
      object-fit: cover;
     }
    }
    @if $type == sp{
     img {
      height: 128vw;
     }
    }
    @if $type == pc{
     img {
      height: 100%;
     }
    }
   }
   
   .heroSlideText {
    @if $type == ""{
    z-index: 1;
    }
    @if $type == sp{
     padding: 0 20px 15vw;

    }
    @if $type == pc{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
     position: absolute;
     left: 5.7447vw;
     bottom: 8.673vw;
     //bottom: 4.3617vw;
    }
   }

   .heroTitle {
    @if $type == ""{
     @include fontPo;
     color: #fff;
     font-weight: 300;
     font-style: italic;
    }
    @if $type == sp{
     font-size: 9.4vw;
     line-height: 1.02;
     letter-spacing: 0.045em;
    }
    @if $type == pc{
     font-size: 3.913vw;
     line-height: 1.18;
     text-shadow: 4px 4px 4px rgba(5,23,35, .2);
     //letter-spacing: 0.05em;
    }
   }

   .heroSlideNote {
    @if $type == ""{
     @include fontHi;
     color: #fff;
     font-weight: bold;
    }
    @if $type == sp{
     margin-top: 4.34vw;
     font-size: 4.16vw;
     line-height: 1.68;
     letter-spacing: 0.01em;
    }
    @if $type == pc{
     margin-top: 0.8695vw;
     font-size: 1.28vw;
     line-height: 1.72;
     letter-spacing: 0.01em;
    }
   }
   
   .heroSlideBtn {
    @if $type == ""{
     display: inline-block;
     color: #fff;
     border: 1px solid #fff;
     position: relative;
     overflow: hidden;
    }
    @if $type == sp{
     padding: 12px 40px 12px 20px; 
     margin-top: 2.358vw;
     font-size: 3.73vw;
    }
    @if $type == pc{
     padding: 0.7vw 2.71vw 0.7vw 1.358vw; 
     margin-top: 1.358vw;
     font-size: 0.869vw;
     
     > span {
      position: relative;
      z-index: 1;
     }
    }
    
    &.btnBlack {
     @if $type == ""{
      color: #000000;
      border-color: #000000;
      
      &:before {
       background-image: url(../images/common/icon_dash_black.svg);
      }
     }
    }
    
    &:before {
     @if $type == ""{
      content: "";
      display: block;
      background: url(../images/common/icon_dash_white.svg) no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
     }
     @if $type == sp{
      width: 11px;
      height: 13px;
      right: 12px;
     
     }
     @if $type == pc{
      width: .8vw;
      height: .8vw;
      right: 1vw;
     }
    }
   }

   &.slick-current:before {
    @if $type == ""{
     content: "";
     width: 0;
     height: 3px;
     background: $c_base;
     position: absolute;
     bottom: 0;
     left: 0;
     z-index: 12;
    }
    @if $type == sp{
     animation: 4s 2s slickActiveSP linear;
    }
    @if $type == pc{
     animation: 4s 2s slickActivePC linear;
    }
   }
/*
   &:nth-child(1) {
    @if $type == sp{
     background: url(../images/home/hero_slide_bg01_sp.jpg) no-repeat center;
     background-size: cover;
    }
    @if $type == pc{
     background: url(../images/home/hero_slide_bg01.jpg) no-repeat center;
     background-size: cover;
    }
   }

   &:nth-child(2) {
    @if $type == ""{
     background: url(../images/home/hero_slide_bg02.jpg) no-repeat center;
     background-size: cover;
    }
   }

   &:nth-child(3) {
    @if $type == ""{
     background: url(../images/home/hero_slide_bg03.jpg) no-repeat center;
     background-size: cover;
    }
   }

   &:nth-child(4) {
    @if $type == ""{
     background: url(../images/home/hero_slide_bg04.jpg) no-repeat center;
     background-size: cover;
    }
   }

   &:nth-child(5) {
    @if $type == ""{
     background: url(../images/home/hero_slide_bg05.jpg) no-repeat center;
     background-size: cover;
    }
   }

   &:nth-child(6) {
    @if $type == ""{
     background: url(../images/home/hero_slide_bg06.jpg) no-repeat center;
     background-size: cover;
    }
   }
   */
  }
  
  .slick-dots {
   @if $type == ""{
   }
   @if $type == sp{
    bottom: 4vw;
    text-align: right;
    position: absolute;
    right: 20px;
   }
   @if $type == pc{
    width: calc(100% - 40.9vw);
    text-align: left;
   }
   
   li {
    @if $type == ""{
     &:not(:nth-child(n + 10)){
      button:before {
       content: "0";
       width: inherit;
       height: inherit;
       line-height: inherit;
       text-align: left;
       font-size: inherit;
       opacity: 1;
       position: inherit;
       top: inherit;
       transition: .2s color;
      }
     }
     
     &.slick-active {
       button {
        color: $c_base;
        
        &:before {
         color: $c_base;
        }
       }
     }
    }
    @if $type == sp{
    }
    @if $type == pc{ 
     + li {
      margin-left: .5vw;
     }
    }
    
    button {
     @if $type == ""{
      padding: 0;
      min-width: 2em;
      font-size:initial;
      color:initial;
      background:initial;
     }
     @if $type == sp{
      color: #fff;
      
      &:before {
       color: #fff;
      }
     }
     @if $type == pc{
      font-size: 0.79vw;
      z-index: 10;
      position: absolute;
      margin-top: 0.7804vw;
      transition: .2s color;
      
      &:hover {
       color: $c_base;
       &:before {
        color: $c_base;
       }
      }
     }
     
     
    }
   }
  }
 }

 .slickCounter {
  @if $type == sp{
   display: none;
  }
  @if $type == pc{
   font-size: 0.79vw;
   letter-spacing: 0.01em;
   z-index: 10;
   position: absolute;
   margin-top: 0.3804vw;

   span {
    @include fontRo;
    font-weight: 600;
   }

   .current {
    margin-right: 6px;
   }

   .total {
    margin-left: 6px;
    color: $c_base;
   }
  }

 }
 .heroNews {
  @if $type == ""{
   background: #fff;
   z-index: 11;
  }
  @if $type == sp{
   display: flex;
   padding: 0 20px 0 0;
  }
  @if $type == pc{
   display: flex;
   align-items: center;
   width: 40.9vw;
   height: 5.319vw;
   padding: 0 2.3vw 0 0;
   position: absolute;
   bottom: -1.5vw;
   right: 0;
  }

  .heroNewsTitle {
   @if $type == ""{
    @include fontRo;
    font-weight: 600;
    color: #fff;
    background: $c_base;
   }
   @if $type == sp{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 84px;
    padding: 10px 0;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.08em;
   }
   @if $type == pc{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 6.1413vw;
    height: 100%;
    padding: 0 1vw;
    margin-right: 1.63vw;
    font-size: 0.92vw;
   }
  }

  .newsList {
   @if $type == sp{
    padding: 19px 0 19px 0;
   }
   @if $type == pc{
    width: 100%;
   }
   
   > li {
    @if $type == ""{
    }
    @if $type == sp{
     font-size: 14px;
    }
    @if $type == pc{
     display: flex;
     flex-direction: column;
     justify-content: center;
     font-size: 0.79vw;
    }

    .newsListDate {
     @if $type == ""{
      @include fontPo; 
      font-weight: 500;
     }
     @if $type == sp{
      width: 80px;
      flex: none;
     }
     @if $type == pc{
      width: 5.6383vw;
      font-size: 0.79vw;
      flex: none;
     }
    }

    .newsListTag {
     @if $type == ""{
      display: none !important;
      color: $c_base;
      @include fontRo; 
      font-weight: 500;
     }
     @if $type == sp{
      flex: none;
     }
     @if $type == pc{
      width: 5.6383vw;
      font-size: 0.79vw;
      flex: none;
     }
    }

    .newsListNote {
     @if $type == ""{
      font-weight: 500;
     }
     @if $type == sp{
     }
     @if $type == pc{
     }

     a {
      @if $type == ""{
       display: block;
      }
      @if $type == sp{
      }
      @if $type == pc{
       width: 31vw;
       transition: .2s color;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;

       &:hover {
        color: $c_base;
       }
      }
     }
    }
   }
  }
 }
}

#topProduct {
 @if $type == ""{
  position: relative;
 }
 @if $type == sp{
   background: #f2f2f2 url(../images/home/product_bg_sp.jpg) no-repeat;
  background-size: 100% auto;
  box-shadow: 0 0px 3px -3px rgba(0, 0, 0,  .05) inset;
  position: relative;

  &:before {
   content: "";
   pointer-events: none;
   width: 100%;
   height: 100%;
   position: absolute;
  }
 }
 @if $type == pc{

  box-shadow: 0 0px 3px -3px rgba(0, 0, 0,  .05) inset;

  &:before {
   content: "";
   width: 100%;
   height: calc(100% + 228px);
    background: #f2f2f2 url(../images/home/product_bg.jpg) no-repeat top left;
   background-size: cover;
   position: absolute;
   bottom: 0;
   left: 0;
  }
 }

 .inner {
  @if $type == ""{
   position: relative;
  }
  @if $type == sp{
   padding: 42px 20px 60px;
  }
  @if $type == pc{
   padding-top: 82px;
   padding-bottom: 80px;

  }
 }

 .sectionHeader {
  @if $type == ""{
  }
  @if $type == sp{
   margin-bottom: 27px;
  }
  @if $type == pc{
   margin-bottom: 49px;
  }
 }

 .productBox {
  @if $type == ""{
   background: #fff;
  }
  @if $type == sp{
   box-shadow: 0 8px 4px rgba(0,0,0,.05);
  }
  @if $type == pc{
   box-shadow: 0 16px 9px rgba(0,0,0,.05);
  }

  .productNav {
   ul {
    @if $type == ""{
     display: flex;
    }
    @if $type == sp{
    }
    @if $type == pc{
    }

    > li {
     @if $type == ""{
      width: 50%;
      text-align: center;
      position: relative;
      transition: .2s color, .2s background;
     }
     @if $type == sp{
      padding: 15px 0 16px;
      font-size: 14px;
     }
     @if $type == pc{
      padding: 13px 10px 18px;
      font-size: 22px;
     }

     &:not(.isActive) {
      @if $type == ""{
       color: #fff;
       background: $c_navy;
       cursor: pointer;
      }
      @if $type == pc{
       &:hover {
        background: #13192a;
       }
      }
     }

     &.isActive {
      &:before {
       @if $type == ""{
        content: "";
        display: block;
        width: 100%;
        background: $c_base;
        position: absolute;
        top: 0;
        left: 0;
       }
       @if $type == sp{
        height: 3px;
       }
       @if $type == pc{
        height: 5px;
       }

      }
     }
    }
   }
  }

  .productContent {
   @if $type == ""{
    &:not(.isActive) {
     display: none;
    }
   }
   @if $type == sp{
    padding: 25px 15px 9px;
   }
   @if $type == pc{
    padding: 45px 45px 37px;
   }

   .topProductList {
    @if $type == ""{
     display: flex;
     flex-wrap: wrap;
    }
    @if $type == sp{
     justify-content: space-between;
    }
    @if $type == pc{
    }

    > li {
     @if $type == ""{
     }
     @if $type == sp{
      width: 48.8%;
     }
     @if $type == pc{
      width: 23.4767%;

      &:not(:nth-child(4n)) {
       margin-right: 2.03%;
      }
      &:nth-child(n + 5) {
       margin-top: 20px;
      }
     }

     > a {
      @if $type == ""{
       display: block;
       color: #000;
      }
      @if $type == sp{
      }
      @if $type == pc{

       &:hover {
        .topProductThumb {
         &:after {
          opacity: 1;
         }

         &:before {
          left: 0;
         }
        }
       }
      }
     }

     .topProductThumb {
      @if $type == ""{
      }
      @if $type == sp{
      }
      @if $type == pc{
       position: relative;
       overflow: hidden;
       
       &:hover {
        img {
         transform: scale(1.1);
        }
       }
       
       img {
         transition: .2s transform;
       }
      }

     }

     .topProductTitle {
      @if $type == ""{
      }
      @if $type == sp{
       padding-right: 12px;
       margin-top: 10px;
       margin-bottom: 17px;
       font-size: 13px;
       line-height: 1.24;
       position: relative;

       &:before {
        content: "";
        display: block;
        width: 6px;
        height: 9px;
        background: url(../images/common/icon_arrow_pink.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 4px;
        right: 2px;
       }
      }
      @if $type == pc{
       margin-top: 7px;
       line-height: 1.4;
      }
     }

    }
   }
  }
 }
}

#topBnr {
 @if $type == ""{

 }
 @if $type == sp{

 }
 @if $type == pc{
  background: #fafafa url(../images/home/topbnr_bg.png) no-repeat right top;
  background-size: auto 100%;
 }

 .inner {
  @if $type == ""{

  }
  @if $type == sp{
   padding: 62px 20px 27px;
  }
  @if $type == pc{
   padding-top: 74px;
   padding-bottom: 88px;
  } 

  .topBnrList {
   @if $type == ""{

   }
   @if $type == sp{

   }
   @if $type == pc{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
   }

   > li {
    @if $type == ""{
    }
    @if $type == sp{
     &:last-child {
      .topBnrNote {
       padding-bottom: 0;
      }
     }
    }
    @if $type == pc{
     width: calc(50% - 15px);
     
     &:nth-child(n + 3){
      margin-top: 78px;
     }
    }

    &:nth-child(1) .topBnrArea > a:after {
     @if $type == ""{
      background: url(../images/home/bnr_worries.png) no-repeat center right;
      background-size: contain;
     }
    } 

    &:nth-child(2) .topBnrArea > a:after {
     @if $type == ""{
      background: url(../images/home/bnr_document.png) no-repeat center right;
      background-size: contain;
     }
    } 
    
    &:nth-child(3) .topBnrArea > a:after {
     @if $type == ""{
      background: url(../images/home/bnr_construction.png) no-repeat center right;
      background-size: contain;
     }
    }
    
    &:nth-child(4) .topBnrArea > a:after {
     @if $type == ""{
      background: url(../images/home/bnr_movie.png) no-repeat center right;
      background-size: contain;
     }
    } 

    .topBnrArea {
     @if $type == pc{
      background: #fff;
     }
     
     > a {
      @if $type == ""{
       border: 1px solid #c90056;
       position: relative;
       overflow: hidden;
      }
      @if $type == sp{
       display: flex;
       align-items: center;
       height: 126px;

       > p {
        z-index: 1;
       }
      }
      @if $type == pc{
       display: flex;
       align-items: center;
       height: unquote("min(220px, 18vw)");

       &:before {
        content: "";
        display: block;
        width: 0;
        height: 100%;
        background: $c_base;
        position: absolute;
        top: 0;
        left: 0;
        transition: .3s width;
       }

       &:hover {
        &:before {
         width: 100%;
        }

        > p {
         color: #fff;
         text-shadow: 1px 1px 2px rgba(201, 0 , 86, 1);
        }
       }
      }

      &:after {
       @if $type == ""{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
       }
       @if $type == sp{
        right: -25px;
       }
       @if $type == pc{
        right: 0;
       }
      }

      > p {
       @if $type == ""{
        color: $c_base;
        position: relative;
       }
       @if $type == sp{
        padding-left: 15px;
        padding-right: 120px;
        font-size: 18px;
        line-height: 1.44;
        text-shadow: 1px 1px 2px rgba(255,255,255, 1);
       }
       @if $type == pc{
        padding-left: 26px;
        font-size: 22px;
        line-height: 1.3636;
        text-shadow: 1px 1px 2px rgba(255,255,255, 1);
        transition: .2s color, .2s text-shadow;
        position: relative;
        z-index: 10;
       }
      } 
     } 
    }

    .topBnrNote {
     @if $type == ""{
     }
     @if $type == sp{
      padding: 6px 15px 30px;
     }
     @if $type == pc{
      padding-top: 10px;
      font-size: 15px;
     }
    }
   }
  }
 }
}

#topPickup {
 @if $type == ""{
  background: url(../images/home/pickup_bg.jpg) no-repeat;
  background-size: cover;
 }
 @if $type == sp{
  padding-top: 52px;
  padding-bottom: 44px;
  overflow: hidden;
 }
 @if $type == pc{
 }

 .inner {
  @if $type == sp{
   max-width: inherit;
  }
  @if $type == pc{
   padding-top: 86px;
   padding-bottom: 94px;
  }
 }

 .topPickupList {
  @if $type == ""{
  }
  @if $type == sp{
  }
  @if $type == pc{
  }

  > li {
   @if $type == ""{
   }
   @if $type == sp{
   }
   @if $type == pc{
    display: flex;
    justify-content: space-between;
   }

   + li {
    @if $type == ""{
    }
    @if $type == sp{
     margin-top: 40px;

     .sectionTitle {
      padding-top: 42px;
      position: relative;

      &:before {
       content: "";
       display: block;
       width: calc(100vw - 140px);
       height: 1px;
       background: #fff;
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       margin: auto;
      }
     }
    }
    @if $type == pc{
     margin-top: 60px;
    }
   }

   .sectionHeader {
    @if $type == ""{
    }
    @if $type == sp{
     @include maxSP;
     padding-right: 35px;
     padding-left: 35px;
     margin-bottom: 30px;
    }
    @if $type == pc{
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     width: 23.66%;
     border-top: 1px solid #fff;
     margin-left: 0.58%;
    }

    .sectionTitle {
     @if $type == ""{
     }
     @if $type == sp{
     }
     @if $type == pc{
      margin-bottom: 10px;
      line-height: 1.33;
     }
    }

    .sectionTitleSub {
     @if $type == ""{
     }
     @if $type == sp{
     }
     @if $type == pc{
      line-height: 1.25;
     }
    }
   }


  }
 }
}

#topNews {
 @if $type == ""{

 }
 @if $type == sp{
 }
 @if $type == pc{
  background: #fff;
 }

 .inner {
  @if $type == ""{
  }
  @if $type == sp{
   padding: 46px 20px 59px;
  }
  @if $type == pc{
   display: flex;
   justify-content: space-between;
   padding-top: 76px;
   padding-bottom: 70px;
   position: relative;
  }

  .sectionHeader {
   @if $type == ""{
   }
   @if $type == sp{
    margin-bottom: 30px;
   }
   @if $type == pc{
    width: 300px;
    margin-top: 24px;
    flex: none;
   }
  }

  .topNewsBody {
   @if $type == ""{
   }
   @if $type == sp{

   }
   @if $type == pc{
    width: 67.4167%;
    margin-left: auto;
    padding-left: 20px;
   }

   .newsList {
    @if $type == ""{
    }
    @if $type == sp{
     border-top: 1px solid #f2f2f2;
    }
    @if $type == pc{
     border-top: 1px solid #f2f2f2;
    }

    > li {
     @if $type == ""{
     }
     @if $type == sp{
      display: flex;
      flex-wrap: wrap;
      padding: 18px 15px;
      border-bottom: 1px solid #f2f2f2;
     }
     @if $type == pc{
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;
      padding: 26px 20px;
     }

     .newsListDate {
      @if $type == ""{
       @include fontRo;
      }
      @if $type == sp{
       min-width: 80px;
       font-size: 14px;
      }
      @if $type == pc{

       + p:not([class]) {
        width: 131px;
       }

       width: 108px;
       flex: none;
      }
     }

     .newsListTag {
      @if $type == ""{
       &.tagProduct {
        background: #556fb5;
       }

       &.tagNews {
        background: #c90056;
       }

       &.tagPressrelease {
        background: #b59355;
       }

       &.tagContribution {
        background: #46976e;
       }
      }
      @if $type == sp{
       padding: 0 1em;
       text-align: center;
       color: #fff;
       font-size: 11px;
       line-height: 20px;
      }
      @if $type == pc{
       display: block;
       width: 181px;
       margin-right: 30px;
       flex: none;
       font-size: 13px;
       color: #fff;
       text-align: center;
      }
     }

     .newsListNote {
      @if $type == ""{
      }
      @if $type == sp{
       width: 100%;
       margin-top: 7px;

       a {
        display: inline-block;
       }
      }
      @if $type == pc{

       a {
        display: inline-block;
        transition: .2s color;

        &:hover {
         color: $c_base;
        }
       }
      }
     }
    }
   }

   .btnWrap {
    @if $type == ""{
    }
    @if $type == sp{
     padding: 0 15px;
     margin-top: 30px;
    }
    @if $type == pc{
     position: absolute;
     left: 80px;
     top: 214px;

     .commonBtn {
      min-width: 200px;
     }
    }
   }
  }

 }
}

@keyframes aboutItem1 {
 0% {
  top: 3.2609vw;
  left: 3.6957vw;
  transform: rotateY(90deg);
 }
 5% {
  transform: none;
 }
 40% {
  top: 3.2609vw;
  left: 3.6957vw;
  transform: none;
 } 
 50% {
  top: 12.9348vw;
  left: 24.1848vw;
  transform: rotateY(90deg);
 }
 55% {
  transform: none;
  z-index: 1;
 }
 90% {
  top: 12.9348vw;
  left: 24.1848vw;
  transform: none;
 }
 100% {
  top: 3.2609vw;
  left: 3.6957vw;
  transform: rotateY(90deg);
  z-index: 1;
 }
}

@keyframes aboutItem2 {
 0% {
  top: 12.9348vw;
  left: 24.1848vw;
  transform: rotateY(90deg);
  z-index: 1;
 }
 5% {
  transform: none;
 }
 40% {
  top: 12.9348vw;
  left: 24.1848vw;
  transform: none;
 } 
 50% {
  top: 3.2609vw;
  left: 3.6957vw;
  transform: rotateY(90deg);
  z-index: 1;
 }
 55% {
  transform: none;
  z-index: 0;
 }
 90% {
  top: 3.2609vw;
  left: 3.6957vw;
  transform: none;
 }
 100% {
  top: 12.9348vw;
  left: 24.1848vw;
  transform: rotateY(90deg);
 }
}

#topAbout {
 @if $type == ""{
 }
 @if $type == sp{
  background: $c_navy;
 }
 @if $type == pc{
  display: flex;
  flex-wrap: wrap;
  position: relative;
 }

 .sectionHeader {
  @if $type == ""{
  }
  @if $type == sp{
   padding: 55px 0 24px;
   background: #fff;
  }
  @if $type == pc{
   width: 100%;
   padding: 75px 0 44px;
   margin-bottom: 0;
  }

  .sectionTitle {
   @if $type == ""{
   }
   @if $type == sp{
   }
   @if $type == pc{
   }
  }

  .sectionTitleSub {
   @if $type == ""{
   }
   @if $type == sp{
   }
   @if $type == pc{
   }
  }

 }

 .topAboutSlide {
  @if $type == ""{
  }
  @if $type == sp{
   padding-bottom: 28px;
   margin-bottom: 35px;
  }
  @if $type == pc{
   width: 50%;
   position: relative;
   background: $c_navy;
  }

  .topAboutSlideItem {
   @if $type == ""{
   }
   @if $type == sp{
    width: 69vw;
    margin: 0 15.5vw;
   }
   @if $type == pc{
    width: 21.6848vw;
    height: 21.8478vw;
    position: absolute;

    &:nth-child(1) {
     top: 3.2609vw;
     left: 3.6957vw;
     animation: 10s aboutItem1 infinite ease-in-out;
    }    
    &:nth-child(2) {
     top: 12.9348vw;
     left: 24.1848vw;
     animation: 10s aboutItem2 infinite ease-in-out;
    }

    a {
     display: block;
     transition: .2s box-shadow, .2s transform;

     &:hover {
      box-shadow: 0 3px 10px rgba(0, 0, 0, .33%);
      transform: translateY(-0.8435vw);
     }

    }
   }
   @media all and (-ms-high-contrast: none) and (min-width: 981px) {
    a {
     &:hover {
      transform: translateY(-5px) !important;
     }
    }
   }
  }

  .slick-dots {
   @if $type == sp{
    bottom: 0;

    > li {
     width: 8px;
     height: 8px;
     margin: 0 5px;

     button {
      width: 8px;
      height: 8px;
      padding: 0;
      border-radius: 50%;
      background: #fff;
     }

     &.slick-active {
      button {
       background: $c_base;
      }
     }
    }
   }
  }
 } 

 .topAboutList {
  @if $type == ""{
  }
  @if $type == sp{
   display: flex;
   flex-wrap: wrap;
  }
  @if $type == pc{
   display: flex;
   flex-wrap: wrap;
   width: 50%;
  }

  > li {
   @if $type == ""{
   }
   @if $type == sp{
    width: 50%;
    height: 37.3333vw;
   }
   @if $type == pc{
    width: 50%;
    height: 19.0217vw;
   }

   &:first-child {
    @if $type == ""{
     background: url(../images/home/about_support.jpg) no-repeat center;
     background-size: cover;
    }
   }
   &:nth-child(2) {
    @if $type == ""{
     background: url(../images/home/about_work.jpg) no-repeat center;
     background-size: cover;
    }
   }
   &:nth-child(3) {
    @if $type == ""{
     background: url(../images/home/about_contribution.jpg) no-repeat center;
     background-size: cover;
    }
   }
   &:nth-child(4) {
    @if $type == ""{
     background: url(../images/home/about_award.jpg) no-repeat center;
     background-size: cover;
    }
   }

   > a {
    @if $type == ""{
     color: #fff;
    }
    @if $type == sp{
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     height: 37.3333vw;
     padding: 0 15px;
    }
    @if $type == pc{
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     width: 100%;
     height: 19.0217vw;
     padding: 0 20px;
     text-align: center;
     position: relative;

     &:before {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      background: rgba(48, 59, 84, .7);
      position: absolute;
      top: 0;
      left: 0;
      transition: .2s width;
     }

     &:hover {
      &:before {
       width: 100%;
      }
     }
    }

    .topAboutListSub {
     @if $type == ""{
     }
     @if $type == sp{
      display: none !important;
     }
     @if $type == pc{
      font-size: 0.9783vw;
      position: relative;
     }
    }

    .topAboutListTitle {
     @if $type == ""{
     }
     @if $type == sp{
      display: flex;
      flex-direction: column;
      font-size: unquote("min(4.8vw, 18px)");
      text-align: center;
     }
     @if $type == pc{
      font-size: 1.3043vw;
      position: relative;
     }
    }
   }
  }
 }
}