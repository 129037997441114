@charset "UTF-8";

// IMPPRT INIT

@import "_setting";
@import "_slick";
@import "_slick-theme";

$type: "";
@import "_home";

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
 $type: sp;
 @import "_home";
}


// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
 $type: pc;
 @import "_home";
}


// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
 $type: tb;
 @import "_home";
}


// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
 $type: print;
}
