@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@keyframes scroll {
  0% {
    height: 98px;
  }
  70% {
    height: 0;
  }
  100% {
    height: 0;
  }
}
@keyframes slickActiveSP {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes slickActivePC {
  0% {
    width: 0;
  }
  100% {
    width: calc(100% - 40.1vw);
  }
}
main .sectionHeader .sectionTitle {
  text-align: center;
  font-weight: 500;
}
main .sectionHeader .sectionTitleSub {
  text-align: center;
  color: #c90056;
  line-height: 1;
  font-weight: bold;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
#topHero {
  position: relative;
}
#topHero .heroContent {
  overflow: hidden;
}
#topHero .heroSlide .heroSlideItem {
  /*
     &:nth-child(1) {
      @if $type == sp{
       background: url(../images/home/hero_slide_bg01_sp.jpg) no-repeat center;
       background-size: cover;
      }
      @if $type == pc{
       background: url(../images/home/hero_slide_bg01.jpg) no-repeat center;
       background-size: cover;
      }
     }

     &:nth-child(2) {
      @if $type == ""{
       background: url(../images/home/hero_slide_bg02.jpg) no-repeat center;
       background-size: cover;
      }
     }

     &:nth-child(3) {
      @if $type == ""{
       background: url(../images/home/hero_slide_bg03.jpg) no-repeat center;
       background-size: cover;
      }
     }

     &:nth-child(4) {
      @if $type == ""{
       background: url(../images/home/hero_slide_bg04.jpg) no-repeat center;
       background-size: cover;
      }
     }

     &:nth-child(5) {
      @if $type == ""{
       background: url(../images/home/hero_slide_bg05.jpg) no-repeat center;
       background-size: cover;
      }
     }

     &:nth-child(6) {
      @if $type == ""{
       background: url(../images/home/hero_slide_bg06.jpg) no-repeat center;
       background-size: cover;
      }
     }
     */
}
#topHero .heroSlide .heroSlideItem > a {
  display: block;
  width: 100%;
  height: 100%;
}
#topHero .heroSlide .heroSlideItem .heroSlideImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
#topHero .heroSlide .heroSlideItem .heroSlideImg img {
  object-fit: cover;
}
#topHero .heroSlide .heroSlideItem .heroSlideText {
  z-index: 1;
}
#topHero .heroSlide .heroSlideItem .heroTitle {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  color: #fff;
  font-weight: 300;
  font-style: italic;
}
#topHero .heroSlide .heroSlideItem .heroSlideNote {
  font-family: "Hiragino Kaku Gothic ProN", "Noto Sans JP", sans-serif;
  color: #fff;
  font-weight: bold;
}
#topHero .heroSlide .heroSlideItem .heroSlideBtn {
  display: inline-block;
  color: #fff;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden;
}
#topHero .heroSlide .heroSlideItem .heroSlideBtn.btnBlack {
  color: #000000;
  border-color: #000000;
}
#topHero .heroSlide .heroSlideItem .heroSlideBtn.btnBlack:before {
  background-image: url(../images/common/icon_dash_black.svg);
}
#topHero .heroSlide .heroSlideItem .heroSlideBtn:before {
  content: "";
  display: block;
  background: url(../images/common/icon_dash_white.svg) no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}
#topHero .heroSlide .heroSlideItem.slick-current:before {
  content: "";
  width: 0;
  height: 3px;
  background: #c90056;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 12;
}
#topHero .heroSlide .slick-dots li:not(:nth-child(n+10)) button:before {
  content: "0";
  width: inherit;
  height: inherit;
  line-height: inherit;
  text-align: left;
  font-size: inherit;
  opacity: 1;
  position: inherit;
  top: inherit;
  transition: 0.2s color;
}
#topHero .heroSlide .slick-dots li.slick-active button {
  color: #c90056;
}
#topHero .heroSlide .slick-dots li.slick-active button:before {
  color: #c90056;
}
#topHero .heroSlide .slick-dots li button {
  padding: 0;
  min-width: 2em;
  font-size: initial;
  color: initial;
  background: initial;
}
#topHero .heroNews {
  background: #fff;
  z-index: 11;
}
#topHero .heroNews .heroNewsTitle {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: 600;
  color: #fff;
  background: #c90056;
}
#topHero .heroNews .newsList > li .newsListDate {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  font-weight: 500;
}
#topHero .heroNews .newsList > li .newsListTag {
  display: none !important;
  color: #c90056;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: 500;
}
#topHero .heroNews .newsList > li .newsListNote {
  font-weight: 500;
}
#topHero .heroNews .newsList > li .newsListNote a {
  display: block;
}

#topProduct {
  position: relative;
}
#topProduct .inner {
  position: relative;
}
#topProduct .productBox {
  background: #fff;
}
#topProduct .productBox .productNav ul {
  display: flex;
}
#topProduct .productBox .productNav ul > li {
  width: 50%;
  text-align: center;
  position: relative;
  transition: 0.2s color, 0.2s background;
}
#topProduct .productBox .productNav ul > li:not(.isActive) {
  color: #fff;
  background: #34405b;
  cursor: pointer;
}
#topProduct .productBox .productNav ul > li.isActive:before {
  content: "";
  display: block;
  width: 100%;
  background: #c90056;
  position: absolute;
  top: 0;
  left: 0;
}
#topProduct .productBox .productContent:not(.isActive) {
  display: none;
}
#topProduct .productBox .productContent .topProductList {
  display: flex;
  flex-wrap: wrap;
}
#topProduct .productBox .productContent .topProductList > li > a {
  display: block;
  color: #000;
}
#topBnr .inner .topBnrList > li:nth-child(1) .topBnrArea > a:after {
  background: url(../images/home/bnr_worries.png) no-repeat center right;
  background-size: contain;
}
#topBnr .inner .topBnrList > li:nth-child(2) .topBnrArea > a:after {
  background: url(../images/home/bnr_document.png) no-repeat center right;
  background-size: contain;
}
#topBnr .inner .topBnrList > li:nth-child(3) .topBnrArea > a:after {
  background: url(../images/home/bnr_construction.png) no-repeat center right;
  background-size: contain;
}
#topBnr .inner .topBnrList > li:nth-child(4) .topBnrArea > a:after {
  background: url(../images/home/bnr_movie.png) no-repeat center right;
  background-size: contain;
}
#topBnr .inner .topBnrList > li .topBnrArea > a {
  border: 1px solid #c90056;
  position: relative;
  overflow: hidden;
}
#topBnr .inner .topBnrList > li .topBnrArea > a:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
#topBnr .inner .topBnrList > li .topBnrArea > a > p {
  color: #c90056;
  position: relative;
}
#topPickup {
  background: url(../images/home/pickup_bg.jpg) no-repeat;
  background-size: cover;
}
#topNews .inner .topNewsBody .newsList > li .newsListDate {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
#topNews .inner .topNewsBody .newsList > li .newsListTag.tagProduct {
  background: #556fb5;
}
#topNews .inner .topNewsBody .newsList > li .newsListTag.tagNews {
  background: #c90056;
}
#topNews .inner .topNewsBody .newsList > li .newsListTag.tagPressrelease {
  background: #b59355;
}
#topNews .inner .topNewsBody .newsList > li .newsListTag.tagContribution {
  background: #46976e;
}
@keyframes aboutItem1 {
  0% {
    top: 3.2609vw;
    left: 3.6957vw;
    transform: rotateY(90deg);
  }
  5% {
    transform: none;
  }
  40% {
    top: 3.2609vw;
    left: 3.6957vw;
    transform: none;
  }
  50% {
    top: 12.9348vw;
    left: 24.1848vw;
    transform: rotateY(90deg);
  }
  55% {
    transform: none;
    z-index: 1;
  }
  90% {
    top: 12.9348vw;
    left: 24.1848vw;
    transform: none;
  }
  100% {
    top: 3.2609vw;
    left: 3.6957vw;
    transform: rotateY(90deg);
    z-index: 1;
  }
}
@keyframes aboutItem2 {
  0% {
    top: 12.9348vw;
    left: 24.1848vw;
    transform: rotateY(90deg);
    z-index: 1;
  }
  5% {
    transform: none;
  }
  40% {
    top: 12.9348vw;
    left: 24.1848vw;
    transform: none;
  }
  50% {
    top: 3.2609vw;
    left: 3.6957vw;
    transform: rotateY(90deg);
    z-index: 1;
  }
  55% {
    transform: none;
    z-index: 0;
  }
  90% {
    top: 3.2609vw;
    left: 3.6957vw;
    transform: none;
  }
  100% {
    top: 12.9348vw;
    left: 24.1848vw;
    transform: rotateY(90deg);
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  #topAbout .topAboutSlide .topAboutSlideItem a:hover {
    transform: translateY(-5px) !important;
  }
}
#topAbout .topAboutList > li:first-child {
  background: url(../images/home/about_support.jpg) no-repeat center;
  background-size: cover;
}
#topAbout .topAboutList > li:nth-child(2) {
  background: url(../images/home/about_work.jpg) no-repeat center;
  background-size: cover;
}
#topAbout .topAboutList > li:nth-child(3) {
  background: url(../images/home/about_contribution.jpg) no-repeat center;
  background-size: cover;
}
#topAbout .topAboutList > li:nth-child(4) {
  background: url(../images/home/about_award.jpg) no-repeat center;
  background-size: cover;
}
#topAbout .topAboutList > li > a {
  color: #fff;
}
@media screen and (max-width: 980px) {
  @keyframes scroll {
    0% {
      height: 98px;
    }
    70% {
      height: 0;
    }
    100% {
      height: 0;
    }
  }
  @keyframes slickActiveSP {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes slickActivePC {
    0% {
      width: 0;
    }
    100% {
      width: calc(100% - 40.1vw);
    }
  }
  main .sectionHeader .sectionTitle {
    margin-bottom: 8px;
    font-size: 24px;
  }
  main .sectionHeader .sectionTitleSub {
    font-size: 12px;
  }
  main .inner {
    max-width: 700px;
    margin: 0 auto;
  }
  #topHero {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
    z-index: 1;
  }
  #topHero .heroScroll {
    display: none !important;
  }
  #topHero .heroContent {
    height: 128vw;
  }
  #topHero .heroSlide {
    margin-bottom: 0;
  }
  #topHero .heroSlide .heroSlideItem {
    display: flex;
    align-items: flex-end;
    height: 128vw;
    /*
       &:nth-child(1) {
        @if $type == sp{
         background: url(../images/home/hero_slide_bg01_sp.jpg) no-repeat center;
         background-size: cover;
        }
        @if $type == pc{
         background: url(../images/home/hero_slide_bg01.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(2) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg02.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(3) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg03.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(4) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg04.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(5) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg05.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(6) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg06.jpg) no-repeat center;
         background-size: cover;
        }
       }
       */
  }
  #topHero .heroSlide .heroSlideItem > a {
    display: flex;
    align-items: flex-end;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideImg img {
    height: 128vw;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideText {
    padding: 0 20px 15vw;
  }
  #topHero .heroSlide .heroSlideItem .heroTitle {
    font-size: 9.4vw;
    line-height: 1.02;
    letter-spacing: 0.045em;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideNote {
    margin-top: 4.34vw;
    font-size: 4.16vw;
    line-height: 1.68;
    letter-spacing: 0.01em;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideBtn {
    padding: 12px 40px 12px 20px;
    margin-top: 2.358vw;
    font-size: 3.73vw;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideBtn:before {
    width: 11px;
    height: 13px;
    right: 12px;
  }
  #topHero .heroSlide .heroSlideItem.slick-current:before {
    animation: 4s 2s slickActiveSP linear;
  }
  #topHero .heroSlide .slick-dots {
    bottom: 4vw;
    text-align: right;
    position: absolute;
    right: 20px;
  }
  #topHero .heroSlide .slick-dots li button {
    color: #fff;
  }
  #topHero .heroSlide .slick-dots li button:before {
    color: #fff;
  }
  #topHero .slickCounter {
    display: none;
  }
  #topHero .heroNews {
    display: flex;
    padding: 0 20px 0 0;
  }
  #topHero .heroNews .heroNewsTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 84px;
    padding: 10px 0;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.08em;
  }
  #topHero .heroNews .newsList {
    padding: 19px 0 19px 0;
  }
  #topHero .heroNews .newsList > li {
    font-size: 14px;
  }
  #topHero .heroNews .newsList > li .newsListDate {
    width: 80px;
    flex: none;
  }
  #topHero .heroNews .newsList > li .newsListTag {
    flex: none;
  }
  #topProduct {
    background: #f2f2f2 url(../images/home/product_bg_sp.jpg) no-repeat;
    background-size: 100% auto;
    box-shadow: 0 0px 3px -3px rgba(0, 0, 0, 0.05) inset;
    position: relative;
  }
  #topProduct:before {
    content: "";
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  #topProduct .inner {
    padding: 42px 20px 60px;
  }
  #topProduct .sectionHeader {
    margin-bottom: 27px;
  }
  #topProduct .productBox {
    box-shadow: 0 8px 4px rgba(0, 0, 0, 0.05);
  }
  #topProduct .productBox .productNav ul > li {
    padding: 15px 0 16px;
    font-size: 14px;
  }
  #topProduct .productBox .productNav ul > li.isActive:before {
    height: 3px;
  }
  #topProduct .productBox .productContent {
    padding: 25px 15px 9px;
  }
  #topProduct .productBox .productContent .topProductList {
    justify-content: space-between;
  }
  #topProduct .productBox .productContent .topProductList > li {
    width: 48.8%;
  }
  #topProduct .productBox .productContent .topProductList > li .topProductTitle {
    padding-right: 12px;
    margin-top: 10px;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 1.24;
    position: relative;
  }
  #topProduct .productBox .productContent .topProductList > li .topProductTitle:before {
    content: "";
    display: block;
    width: 6px;
    height: 9px;
    background: url(../images/common/icon_arrow_pink.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 4px;
    right: 2px;
  }
  #topBnr .inner {
    padding: 62px 20px 27px;
  }
  #topBnr .inner .topBnrList > li:last-child .topBnrNote {
    padding-bottom: 0;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a {
    display: flex;
    align-items: center;
    height: 126px;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a > p {
    z-index: 1;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a:after {
    right: -25px;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a > p {
    padding-left: 15px;
    padding-right: 120px;
    font-size: 18px;
    line-height: 1.44;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
  }
  #topBnr .inner .topBnrList > li .topBnrNote {
    padding: 6px 15px 30px;
  }
  #topPickup {
    padding-top: 52px;
    padding-bottom: 44px;
    overflow: hidden;
  }
  #topPickup .inner {
    max-width: inherit;
  }
  #topPickup .topPickupList > li + li {
    margin-top: 40px;
  }
  #topPickup .topPickupList > li + li .sectionTitle {
    padding-top: 42px;
    position: relative;
  }
  #topPickup .topPickupList > li + li .sectionTitle:before {
    content: "";
    display: block;
    width: calc(100vw - 140px);
    height: 1px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  #topPickup .topPickupList > li .sectionHeader {
    max-width: 700px;
    margin: 0 auto;
    padding-right: 35px;
    padding-left: 35px;
    margin-bottom: 30px;
  }
  #topNews .inner {
    padding: 46px 20px 59px;
  }
  #topNews .inner .sectionHeader {
    margin-bottom: 30px;
  }
  #topNews .inner .topNewsBody .newsList {
    border-top: 1px solid #f2f2f2;
  }
  #topNews .inner .topNewsBody .newsList > li {
    display: flex;
    flex-wrap: wrap;
    padding: 18px 15px;
    border-bottom: 1px solid #f2f2f2;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListDate {
    min-width: 80px;
    font-size: 14px;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListTag {
    padding: 0 1em;
    text-align: center;
    color: #fff;
    font-size: 11px;
    line-height: 20px;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListNote {
    width: 100%;
    margin-top: 7px;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListNote a {
    display: inline-block;
  }
  #topNews .inner .topNewsBody .btnWrap {
    padding: 0 15px;
    margin-top: 30px;
  }
  @keyframes aboutItem1 {
    0% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
    }
    5% {
      transform: none;
    }
    40% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: none;
    }
    50% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
    }
    55% {
      transform: none;
      z-index: 1;
    }
    90% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: none;
    }
    100% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
  }
  @keyframes aboutItem2 {
    0% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
    5% {
      transform: none;
    }
    40% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: none;
    }
    50% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
    55% {
      transform: none;
      z-index: 0;
    }
    90% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: none;
    }
    100% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
    }
  }
  #topAbout {
    background: #34405b;
  }
  #topAbout .sectionHeader {
    padding: 55px 0 24px;
    background: #fff;
  }
  #topAbout .topAboutSlide {
    padding-bottom: 28px;
    margin-bottom: 35px;
  }
  #topAbout .topAboutSlide .topAboutSlideItem {
    width: 69vw;
    margin: 0 15.5vw;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #topAbout .topAboutSlide .topAboutSlideItem a:hover {
    transform: translateY(-5px) !important;
  }
}
@media screen and (max-width: 980px) {
  #topAbout .topAboutSlide .slick-dots {
    bottom: 0;
  }
  #topAbout .topAboutSlide .slick-dots > li {
    width: 8px;
    height: 8px;
    margin: 0 5px;
  }
  #topAbout .topAboutSlide .slick-dots > li button {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
    background: #fff;
  }
  #topAbout .topAboutSlide .slick-dots > li.slick-active button {
    background: #c90056;
  }
  #topAbout .topAboutList {
    display: flex;
    flex-wrap: wrap;
  }
  #topAbout .topAboutList > li {
    width: 50%;
    height: 37.3333vw;
  }
  #topAbout .topAboutList > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 37.3333vw;
    padding: 0 15px;
  }
  #topAbout .topAboutList > li > a .topAboutListSub {
    display: none !important;
  }
  #topAbout .topAboutList > li > a .topAboutListTitle {
    display: flex;
    flex-direction: column;
    font-size: min(4.8vw, 18px);
    text-align: center;
  }
}
@media print, screen and (min-width: 981px) {
  main {
    font-size: 15px;
  }
  @keyframes scroll {
    0% {
      height: 98px;
    }
    70% {
      height: 0;
    }
    100% {
      height: 0;
    }
  }
  @keyframes slickActiveSP {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes slickActivePC {
    0% {
      width: 0;
    }
    100% {
      width: calc(100% - 40.1vw);
    }
  }
  main .sectionHeader .sectionTitle {
    margin-bottom: 2px;
    font-size: 30px;
  }
  main .sectionHeader .sectionTitleSub {
    font-size: 14px;
  }
  main .inner {
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
  }
  #topHero {
    padding-left: 6%;
  }
  #topHero .heroScroll {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    position: absolute;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    left: 2.28%;
    z-index: 10;
  }
  #topHero .heroScroll:before {
    content: "";
    width: 1px;
    height: 98px;
    background: #c90056;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    animation: 1.5s scroll infinite ease-out;
  }
  #topHero .heroScroll a {
    display: block;
    padding-bottom: 110px;
    color: #c90056;
    font-size: 0.69vw;
    letter-spacing: 0.06em;
  }
  #topHero .heroContent {
    height: calc(37.28vw + 30px);
  }
  #topHero .heroSlide {
    position: relative;
    z-index: 11;
  }
  #topHero .heroSlide .heroSlideItem {
    height: 37.28vw;
    /*
       &:nth-child(1) {
        @if $type == sp{
         background: url(../images/home/hero_slide_bg01_sp.jpg) no-repeat center;
         background-size: cover;
        }
        @if $type == pc{
         background: url(../images/home/hero_slide_bg01.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(2) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg02.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(3) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg03.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(4) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg04.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(5) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg05.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(6) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg06.jpg) no-repeat center;
         background-size: cover;
        }
       }
       */
  }
  #topHero .heroSlide .heroSlideItem > a .heroSlideBtn:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: -100%;
    transition: 0.2s left;
  }
  #topHero .heroSlide .heroSlideItem > a:hover .heroSlideBtn:after {
    left: 0;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideImg img {
    height: 100%;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    left: 5.7447vw;
    bottom: 8.673vw;
  }
  #topHero .heroSlide .heroSlideItem .heroTitle {
    font-size: 3.913vw;
    line-height: 1.18;
    text-shadow: 4px 4px 4px rgba(5, 23, 35, 0.2);
  }
  #topHero .heroSlide .heroSlideItem .heroSlideNote {
    margin-top: 0.8695vw;
    font-size: 1.28vw;
    line-height: 1.72;
    letter-spacing: 0.01em;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideBtn {
    padding: 0.7vw 2.71vw 0.7vw 1.358vw;
    margin-top: 1.358vw;
    font-size: 0.869vw;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideBtn > span {
    position: relative;
    z-index: 1;
  }
  #topHero .heroSlide .heroSlideItem .heroSlideBtn:before {
    width: 0.8vw;
    height: 0.8vw;
    right: 1vw;
  }
  #topHero .heroSlide .heroSlideItem.slick-current:before {
    animation: 4s 2s slickActivePC linear;
  }
  #topHero .heroSlide .slick-dots {
    width: calc(100% - 40.9vw);
    text-align: left;
  }
  #topHero .heroSlide .slick-dots li + li {
    margin-left: 0.5vw;
  }
  #topHero .heroSlide .slick-dots li button {
    font-size: 0.79vw;
    z-index: 10;
    position: absolute;
    margin-top: 0.7804vw;
    transition: 0.2s color;
  }
  #topHero .heroSlide .slick-dots li button:hover {
    color: #c90056;
  }
  #topHero .heroSlide .slick-dots li button:hover:before {
    color: #c90056;
  }
  #topHero .slickCounter {
    font-size: 0.79vw;
    letter-spacing: 0.01em;
    z-index: 10;
    position: absolute;
    margin-top: 0.3804vw;
  }
  #topHero .slickCounter span {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-weight: 600;
  }
  #topHero .slickCounter .current {
    margin-right: 6px;
  }
  #topHero .slickCounter .total {
    margin-left: 6px;
    color: #c90056;
  }
  #topHero .heroNews {
    display: flex;
    align-items: center;
    width: 40.9vw;
    height: 5.319vw;
    padding: 0 2.3vw 0 0;
    position: absolute;
    bottom: -1.5vw;
    right: 0;
  }
  #topHero .heroNews .heroNewsTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 6.1413vw;
    height: 100%;
    padding: 0 1vw;
    margin-right: 1.63vw;
    font-size: 0.92vw;
  }
  #topHero .heroNews .newsList {
    width: 100%;
  }
  #topHero .heroNews .newsList > li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.79vw;
  }
  #topHero .heroNews .newsList > li .newsListDate {
    width: 5.6383vw;
    font-size: 0.79vw;
    flex: none;
  }
  #topHero .heroNews .newsList > li .newsListTag {
    width: 5.6383vw;
    font-size: 0.79vw;
    flex: none;
  }
  #topHero .heroNews .newsList > li .newsListNote a {
    width: 31vw;
    transition: 0.2s color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #topHero .heroNews .newsList > li .newsListNote a:hover {
    color: #c90056;
  }
  #topProduct {
    box-shadow: 0 0px 3px -3px rgba(0, 0, 0, 0.05) inset;
  }
  #topProduct:before {
    content: "";
    width: 100%;
    height: calc(100% + 228px);
    background: #f2f2f2 url(../images/home/product_bg.jpg) no-repeat top left;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  #topProduct .inner {
    padding-top: 82px;
    padding-bottom: 80px;
  }
  #topProduct .sectionHeader {
    margin-bottom: 49px;
  }
  #topProduct .productBox {
    box-shadow: 0 16px 9px rgba(0, 0, 0, 0.05);
  }
  #topProduct .productBox .productNav ul > li {
    padding: 13px 10px 18px;
    font-size: 22px;
  }
  #topProduct .productBox .productNav ul > li:not(.isActive):hover {
    background: #13192a;
  }
  #topProduct .productBox .productNav ul > li.isActive:before {
    height: 5px;
  }
  #topProduct .productBox .productContent {
    padding: 45px 45px 37px;
  }
  #topProduct .productBox .productContent .topProductList > li {
    width: 23.4767%;
  }
  #topProduct .productBox .productContent .topProductList > li:not(:nth-child(4n)) {
    margin-right: 2.03%;
  }
  #topProduct .productBox .productContent .topProductList > li:nth-child(n+5) {
    margin-top: 20px;
  }
  #topProduct .productBox .productContent .topProductList > li > a:hover .topProductThumb:after {
    opacity: 1;
  }
  #topProduct .productBox .productContent .topProductList > li > a:hover .topProductThumb:before {
    left: 0;
  }
  #topProduct .productBox .productContent .topProductList > li .topProductThumb {
    position: relative;
    overflow: hidden;
  }
  #topProduct .productBox .productContent .topProductList > li .topProductThumb:hover img {
    transform: scale(1.1);
  }
  #topProduct .productBox .productContent .topProductList > li .topProductThumb img {
    transition: 0.2s transform;
  }
  #topProduct .productBox .productContent .topProductList > li .topProductTitle {
    margin-top: 7px;
    line-height: 1.4;
  }
  #topBnr {
    background: #fafafa url(../images/home/topbnr_bg.png) no-repeat right top;
    background-size: auto 100%;
  }
  #topBnr .inner {
    padding-top: 74px;
    padding-bottom: 88px;
  }
  #topBnr .inner .topBnrList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #topBnr .inner .topBnrList > li {
    width: calc(50% - 15px);
  }
  #topBnr .inner .topBnrList > li:nth-child(n+3) {
    margin-top: 78px;
  }
  #topBnr .inner .topBnrList > li .topBnrArea {
    background: #fff;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a {
    display: flex;
    align-items: center;
    height: min(220px, 18vw);
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background: #c90056;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s width;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a:hover:before {
    width: 100%;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a:hover > p {
    color: #fff;
    text-shadow: 1px 1px 2px rgb(201, 0, 86);
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a:after {
    right: 0;
  }
  #topBnr .inner .topBnrList > li .topBnrArea > a > p {
    padding-left: 26px;
    font-size: 22px;
    line-height: 1.3636;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
    transition: 0.2s color, 0.2s text-shadow;
    position: relative;
    z-index: 10;
  }
  #topBnr .inner .topBnrList > li .topBnrNote {
    padding-top: 10px;
    font-size: 15px;
  }
  #topPickup .inner {
    padding-top: 86px;
    padding-bottom: 94px;
  }
  #topPickup .topPickupList > li {
    display: flex;
    justify-content: space-between;
  }
  #topPickup .topPickupList > li + li {
    margin-top: 60px;
  }
  #topPickup .topPickupList > li .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 23.66%;
    border-top: 1px solid #fff;
    margin-left: 0.58%;
  }
  #topPickup .topPickupList > li .sectionHeader .sectionTitle {
    margin-bottom: 10px;
    line-height: 1.33;
  }
  #topPickup .topPickupList > li .sectionHeader .sectionTitleSub {
    line-height: 1.25;
  }
  #topNews {
    background: #fff;
  }
  #topNews .inner {
    display: flex;
    justify-content: space-between;
    padding-top: 76px;
    padding-bottom: 70px;
    position: relative;
  }
  #topNews .inner .sectionHeader {
    width: 300px;
    margin-top: 24px;
    flex: none;
  }
  #topNews .inner .topNewsBody {
    width: 67.4167%;
    margin-left: auto;
    padding-left: 20px;
  }
  #topNews .inner .topNewsBody .newsList {
    border-top: 1px solid #f2f2f2;
  }
  #topNews .inner .topNewsBody .newsList > li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding: 26px 20px;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListDate {
    width: 108px;
    flex: none;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListDate + p:not([class]) {
    width: 131px;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListTag {
    display: block;
    width: 181px;
    margin-right: 30px;
    flex: none;
    font-size: 13px;
    color: #fff;
    text-align: center;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListNote a {
    display: inline-block;
    transition: 0.2s color;
  }
  #topNews .inner .topNewsBody .newsList > li .newsListNote a:hover {
    color: #c90056;
  }
  #topNews .inner .topNewsBody .btnWrap {
    position: absolute;
    left: 80px;
    top: 214px;
  }
  #topNews .inner .topNewsBody .btnWrap .commonBtn {
    min-width: 200px;
  }
  @keyframes aboutItem1 {
    0% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
    }
    5% {
      transform: none;
    }
    40% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: none;
    }
    50% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
    }
    55% {
      transform: none;
      z-index: 1;
    }
    90% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: none;
    }
    100% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
  }
  @keyframes aboutItem2 {
    0% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
    5% {
      transform: none;
    }
    40% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: none;
    }
    50% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
    55% {
      transform: none;
      z-index: 0;
    }
    90% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: none;
    }
    100% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
    }
  }
  #topAbout {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  #topAbout .sectionHeader {
    width: 100%;
    padding: 75px 0 44px;
    margin-bottom: 0;
  }
  #topAbout .topAboutSlide {
    width: 50%;
    position: relative;
    background: #34405b;
  }
  #topAbout .topAboutSlide .topAboutSlideItem {
    width: 21.6848vw;
    height: 21.8478vw;
    position: absolute;
  }
  #topAbout .topAboutSlide .topAboutSlideItem:nth-child(1) {
    top: 3.2609vw;
    left: 3.6957vw;
    animation: 10s aboutItem1 infinite ease-in-out;
  }
  #topAbout .topAboutSlide .topAboutSlideItem:nth-child(2) {
    top: 12.9348vw;
    left: 24.1848vw;
    animation: 10s aboutItem2 infinite ease-in-out;
  }
  #topAbout .topAboutSlide .topAboutSlideItem a {
    display: block;
    transition: 0.2s box-shadow, 0.2s transform;
  }
  #topAbout .topAboutSlide .topAboutSlideItem a:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.0033);
    transform: translateY(-0.8435vw);
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #topAbout .topAboutSlide .topAboutSlideItem a:hover {
    transform: translateY(-5px) !important;
  }
}
@media print, screen and (min-width: 981px) {
  #topAbout .topAboutList {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }
  #topAbout .topAboutList > li {
    width: 50%;
    height: 19.0217vw;
  }
  #topAbout .topAboutList > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 19.0217vw;
    padding: 0 20px;
    text-align: center;
    position: relative;
  }
  #topAbout .topAboutList > li > a:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    background: rgba(48, 59, 84, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s width;
  }
  #topAbout .topAboutList > li > a:hover:before {
    width: 100%;
  }
  #topAbout .topAboutList > li > a .topAboutListSub {
    font-size: 0.9783vw;
    position: relative;
  }
  #topAbout .topAboutList > li > a .topAboutListTitle {
    font-size: 1.3043vw;
    position: relative;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  @keyframes scroll {
    0% {
      height: 98px;
    }
    70% {
      height: 0;
    }
    100% {
      height: 0;
    }
  }
  @keyframes slickActiveSP {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes slickActivePC {
    0% {
      width: 0;
    }
    100% {
      width: calc(100% - 40.1vw);
    }
  }
  #topHero .heroSlide .heroSlideItem {
    /*
       &:nth-child(1) {
        @if $type == sp{
         background: url(../images/home/hero_slide_bg01_sp.jpg) no-repeat center;
         background-size: cover;
        }
        @if $type == pc{
         background: url(../images/home/hero_slide_bg01.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(2) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg02.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(3) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg03.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(4) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg04.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(5) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg05.jpg) no-repeat center;
         background-size: cover;
        }
       }

       &:nth-child(6) {
        @if $type == ""{
         background: url(../images/home/hero_slide_bg06.jpg) no-repeat center;
         background-size: cover;
        }
       }
       */
  }
  @keyframes aboutItem1 {
    0% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
    }
    5% {
      transform: none;
    }
    40% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: none;
    }
    50% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
    }
    55% {
      transform: none;
      z-index: 1;
    }
    90% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: none;
    }
    100% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
  }
  @keyframes aboutItem2 {
    0% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
    5% {
      transform: none;
    }
    40% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: none;
    }
    50% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: rotateY(90deg);
      z-index: 1;
    }
    55% {
      transform: none;
      z-index: 0;
    }
    90% {
      top: 3.2609vw;
      left: 3.6957vw;
      transform: none;
    }
    100% {
      top: 12.9348vw;
      left: 24.1848vw;
      transform: rotateY(90deg);
    }
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #topAbout .topAboutSlide .topAboutSlideItem a:hover {
    transform: translateY(-5px) !important;
  }
}